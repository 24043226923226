import React from "react"
import PropTypes from "prop-types"
import NavigationBar from "../components/Navbar"
import NewsletterStrip from "../components/newsletterStrip"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group, tag },
  },
}) => (
  <div>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>Tags</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="description" content="All Tags Page" />
    </Helmet>
    <div className="text-center">
      <div className=" sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <h1 className="inline-block px-10 py-4 mt-8 text-xl lg:text-2xl 2xl:text-3xl font-medium text-cool-gray-900 text-center rounded">
        All Content Tags
      </h1>

      <ul className="grid mt-10 mb-8 grid-cols-1 gap-4 lg:gap-8 sm:grid-cols-2 xl:grid-cols-3 lg:mx-20 md:mx-10">
        {group.map(tag => (
          <Link
            to={`/tags/${kebabCase(tag.fieldValue)}/`}
            className="px-5 py-3 font-medium shadow bg-white hover:bg-green-600 hover:text-white text-green-500 text-lg xl:text-xl rounded-3xl text-nowrap"
            key={tag.fieldValue}
          >
            <div className="">
              <p className="overflow-hidden">
                {tag.fieldValue} ({tag.totalCount})
              </p>
            </div>
          </Link>
        ))}
      </ul>
    </div>
    <div className="mb-16 mt-6">
      <NewsletterStrip />
    </div>
  </div>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
