import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default class NewsletterStrip extends React.Component {
  state = {
    email: "",
    responseMessage: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    console.log(target)
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    console.log(result)
    this.setState({ message: result.msg })
  }

  render() {
    console.log(this.props)
    return (
      <div className="mt-6">
        <div className="rounded-2xl grid shadow-md justify-center py-8 px-5 sm:px-7 md:px-9 mx-auto">
          <div className="col-span-2">
            <h3 className="tracking-wider text-xl lg:text-2xl 2xl:text-3xl leading-8 text-gray-900">
              Our Newsletter
            </h3>
            <p className="tracking-wider max-w-sm sm:max-w-xl xl:max-w-2xl mt-3 text-sm xl:text-base 2xl:text-lg leading-6 text-gray-600">
              Subscribe to get well designed content on data structure and
              algorithms, machine learning, system design, object orientd
              programming and math.
            </p>
          </div>
          <div className="col-span-2 max-w-2xl mt-6">
            <div
              className="max-w-xs my-2 prose-lg text-green-600 prose-red text-wrap"
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            />
            <form
              className="sm:flex"
              name="subscribeForm"
              onSubmit={this.handleSubmit}
              id="subscribe-form"
              method="POST"
            >
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                className="block shadow-sm w-full px-4 py-3 text-xs 2xl:text-sm text-left placeholder-gray-600 border-b border-new-purple appearance-none tracking-wider focus:outline-none focus:placeholder-gray-400 focus:shadow-outline focus:border-gray-300"
                placeholder="Email address"
                onFocus={e => (e.target.placeholder = "")}
                onBlur={e => (e.target.placeholder = "Email address")}
              />
              <div className="flex justify-center mt-3 rounded sm:-ml-1 sm:mt-0 sm:flex-shrink-0">
                <button
                  type="submit"
                  className= "flex items-center justify-center px-5 py-3 text-base 2xl:text-lg shadow leading-6 rounded border border-new-purple hover:bg-new-purple text-new-purple hover:text-white transition duration-150">
                  <div className="justify-center flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <p className="ml-2 tracking-wider"> Subscribe</p>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
